<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="商户名称">
          <a-input v-model.trim="query.name" placeholder="请输入"></a-input>
        </query-item>
        <query-item label="联系人">
          <a-input v-model.trim="query.contact" placeholder="请输入"></a-input>
        </query-item>
        <query-item label="手机号">
          <a-input v-model.trim="query.mobile" placeholder="请输入"></a-input>
        </query-item>
        <query-item label="商户状态">
          <a-select v-model="query.status" allowClear placeholder="请选择">
            <a-select-option :value="0">
              启用
            </a-select-option>
            <a-select-option :value="1">
              禁用
            </a-select-option>
          </a-select>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import { storeList, storeStatus } from '@/api/tool';
import queryMixin from '@/mixin/queryMixin';

export default {
  name: 'StoreList',
  mixins: [queryMixin],
  components: {
  },
  props: {},
  data() {
    return {
      query: {
        storeId: '',
        name: '',
        contact: '',
        mobile: '',
        stauts: '',

      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '商户名称',
          dataIndex: 'storeName',
          key: 'storeName',
          width: 200,
        },
        {
          title: '门店数量',
          dataIndex: 'subCount',
          key: 'subCount',
          width: 200,
        },
        {
          title: '联系人',
          dataIndex: 'contact',
          key: 'contact',
          width: 200,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          width: 200,
        },
        {
          title: '邮箱',
          dataIndex: 'mail',
          key: 'mail',
          width: 200,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          customRender: (text, row, index) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row, index) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
              </section>
            );
          }
        }
      ],
      dataList: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.handleQuery();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      this.dataList = [];
      storeList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        this.dataList = list;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'storeAdd'
      });
    },

    // 查看
    handleInfo(row) {
      this.$router.push({
        name: 'storeInfo',
        params: {
          id: row.storeId
        }
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'storeEdit',
        params: {
          id: row.storeId
        }
      });
    },
    // 启用或者禁用
    handleStatus(row) {
      const params = {
        storeId: row.storeId,
        status: row.status === 0 ? 1 : 0
      };

      storeStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
